import * as React from "react";

import CLayout from "../components/contact-layout";

import Seo from "../components/seo";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/styles.scss";
import "../styles/contact.scss";

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`;

const SharedPage = () => <CLayout page="contact" />;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Thank you for sharing." />;

export default SharedPage;
